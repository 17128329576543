document.addEventListener('DOMContentLoaded', function () {
    const showDivLink = document.getElementById('showNewsletter');
    const myDiv = document.getElementById('newsletter');
  
    // Show the div when clicking on the link
    showDivLink.addEventListener('click', function (event) {
      event.preventDefault(); // Prevent default anchor behavior
      myDiv.style.display = 'block';
    });
  
    // Hide the div when clicking outside of it
    document.addEventListener('click', function (event) {
      if (!myDiv.contains(event.target) && event.target !== showDivLink) {
        myDiv.style.display = 'none';
      }
    });
});