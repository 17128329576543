import { initializeKeyboardListener } from "./classes/KeyboardListener";
import Slider from "./modules/Slider";

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import "@/styles/index.scss";
import "./utils/scroll";

import "./modules/House";
import "./modules/DrawerMenu";
import "./modules/ArchiveFilter";
import "./modules/ArchiveReveal";
import "./modules/ArchiveLoad";
import "./modules/HeroReveal";
import "./modules/ToTop";
import "./modules/Newsletter";

// import "./pages/Index";

class App {
	constructor() {
		initializeKeyboardListener();
		this._createSliders();
	}

	_createSliders() {
		const sliders = [...document.querySelectorAll(".slider")];

		sliders.forEach((slider) => {
			new Slider({
				container: slider,
			});
		});
	}
}

new App();

console.log("%c Developed by ANTI - https://anti.as/", "background: #000; color: #fff;");
