import * as THREE from "three";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const loader = new GLTFLoader();
const modelUrl = "/dist/houseModel/houseCenteredModel.gltf";
const container = document.querySelector(".house");

class THREEScene {
  constructor() {
    this.container = container.querySelector(".house__inner");
    this.model = null;
    this.color = window.pageColor;
    this.defaultScale = 300; // Adjust this to match the initial size

    this.lazyLoadScene(); // Initialize lazy loading
  }

  // Function to initialize lazy loading using Intersection Observer
  lazyLoadScene() {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.init(); // Initialize the scene when the container is in view
          this.addHouse();
          observer.unobserve(entry.target); // Stop observing once loaded
        }
      });
    });

    observer.observe(this.container);
  }

  onScroll() {
    const tl = gsap.timeline();

    gsap.to(this.model.rotation, {
      x: 0.3,
      y: "-=3",
      scrollTrigger: {
        trigger: this.container,
        start: "clamp(top bottom)",
        end: "clamp(bottom top)",
        scrub: 0.1,
      },
    });
  }

  addHouse() {
    loader.load(
      modelUrl,
      (gltf) => {
        const model = gltf.scene;

        this.model = model;
        this.model.scale.set(this.defaultScale, this.defaultScale, this.defaultScale); // Initial scale

        model.position.set(0, 0, 0);
        model.traverse((child) => {
          if (child.isMesh) {
            const newMaterial = new THREE.MeshBasicMaterial({ color: this.color });
            child.material = newMaterial;
            child.material.needsUpdate = true;
          }
        });

        this.scene.add(model);
        gsap.set(this.model.rotation, { y: 2 });
        this.updateModelScale(); // Adjust scale after adding
        this.onScroll();
      },
      (xhr) => {
        // console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      (error) => {
        console.log("An error happened", error);
      }
    );
  }

  init() {
    this.setup();
    this.camera();
    this.eventListeners();
    this.render();
  }

  setup() {
    this.scene = new THREE.Scene();
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setSize(this.viewport.width, this.viewport.height);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setClearColor(0xffffff);
    this.container.appendChild(this.renderer.domElement);
  }

  camera() {
    const aspectRatio = this.viewport.aspectRatio;
    const height = this.viewport.width / aspectRatio; // Calculate height based on the 16:9 aspect ratio

    this.camera = new THREE.OrthographicCamera(
      this.viewport.width / -2,
      this.viewport.width / 2,
      height / 2,
      height / -2,
      -400,
      10000
    );
    this.camera.position.set(0, 0, 1000); // Ensure the camera is positioned properly
  }

  render() {
    this.camera.lookAt(this.scene.position);
    this.renderer.render(this.scene, this.camera);

    requestAnimationFrame(() => {
      this.render();
    });
  }

  eventListeners() {
    window.addEventListener("resize", this.onWindowResize.bind(this));
  }

  onWindowResize() {
    const aspectRatio = 16 / 9;
    const width = this.container.clientWidth;
    const height = width / aspectRatio;

    // Update camera aspect ratio and size
    this.camera.left = -width / 2;
    this.camera.right = width / 2;
    this.camera.top = height / 2;
    this.camera.bottom = -height / 2;
    this.camera.updateProjectionMatrix();

    // Update renderer size
    this.renderer.setSize(width, height);

    // Update the model's scale
    this.updateModelScale();
  }

  updateModelScale() {
    if (this.model) {
      const initialWidth = 1500; // Set this to your desired initial width
      const scaleFactor = this.container.clientWidth / initialWidth;

      this.model.scale.set(
        this.defaultScale * scaleFactor,
        this.defaultScale * scaleFactor,
        this.defaultScale * scaleFactor
      );
    }
  }

  get viewport() {
    const width = this.container.clientWidth;
    const height = width / (16 / 9); // Calculate height based on a 16:9 aspect ratio

    return {
      width,
      height,
      aspectRatio: 16 / 9,
    };
  }
}

if (container) {
  const scene = new THREEScene();
}