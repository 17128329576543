const keyCallbacks = {};

const keyEventListener = (event) => {
	const key = event.key.toLowerCase();
	if (keyCallbacks[key]) {
		keyCallbacks[key].forEach((callback) => callback(event));
	}
};

const addKeyListener = (key, callback) => {
	const normalizedKey = key.toLowerCase();
	if (!keyCallbacks[normalizedKey]) {
		keyCallbacks[normalizedKey] = [];
	}
	keyCallbacks[normalizedKey].push(callback);
};

const removeKeyListener = (key, callback) => {
	const normalizedKey = key.toLowerCase();
	if (keyCallbacks[normalizedKey]) {
		keyCallbacks[normalizedKey] = keyCallbacks[normalizedKey].filter((cb) => cb !== callback);
		if (keyCallbacks[normalizedKey].length === 0) {
			delete keyCallbacks[normalizedKey];
		}
	}
};

const initializeKeyboardListener = () => {
	window.addEventListener("keydown", keyEventListener);
};

const cleanupKeyboardListener = () => {
	window.removeEventListener("keydown", keyEventListener);
};

initializeKeyboardListener();

export { addKeyListener, removeKeyListener, initializeKeyboardListener, cleanupKeyboardListener };
