const scrollToTop = document.getElementById('scrollToTop');

const toTop = (e) => {
    e.preventDefault();
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};

if (scrollToTop) {
    scrollToTop.addEventListener("click", toTop);
}