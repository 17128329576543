import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

gsap.set('.archive__item', {
    autoAlpha: 0,
});

document.querySelectorAll('.rows-reveal').forEach(row => {
    gsap.to(row.querySelectorAll('.archive__item'), {
        autoAlpha: 1,
        duration: 0.0,
        delay: 0.1,
        stagger: {
            each: 0.1,
            from: "random",
        },
        scrollTrigger: {
            trigger: row,
            start: 'top 80%',
            toggleActions: 'play none none none',
            once: true,
        }
    });
});