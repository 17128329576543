import { gsap } from "gsap";
import { AppState } from "../classes/StateManager";
import { addKeyListener, removeKeyListener } from "../classes/KeyboardListener";

const menuOpenTrigger = document.querySelector(".drawer-menu-trigger");
const menuCloseTrigger = document.querySelector(".drawer-menu-close");
const drawerMenu = document.querySelector(".drawer-menu");
const drawerMenuLinks = [...drawerMenu.querySelectorAll(".drawer-menu__link")];
// const pageContent = document.querySelector(".page-content");
// const headerLogo = document.querySelector(".header__logo");

// const animatePageUp = () => {
// 	gsap.to(pageContent, {
// 		y: -42,
// 		ease: "power3.inOut",
// 		duration: 0.7,
// 		overwrite: true,
// 	});
// };

// const rotateMenu = gsap.timeline({ paused: true });

// rotateMenu.to(headerLogo, {
// 	rotation: -45,
// 	duration: 0.7,
// 	ease: "power3.inOut",
// 	overwrite: true,
// });

// const animatePageDown = () => {
// 	gsap.to(pageContent, {
// 		y: 0,
// 		ease: "power3.inOut",
// 		duration: 0.8,
// 		overwrite: true,
// 	});
// };

const animateLinks = gsap.timeline({ paused: true });

gsap.set(drawerMenuLinks, {
	autoAlpha: 0,
});

animateLinks.to(drawerMenuLinks, {
	autoAlpha: 1,
	duration: 0,
	delay: 0.2,
	stagger: {
		each: 0.1,
		from: "random",
	},
});

const openDrawerMenu = () => {
	drawerMenu.classList.add("active");
	AppState.setPageLock();
	// animatePageUp();
	// rotateMenu.play();
	animateLinks.play();

	addKeyListener("escape", closeDrawerMenu);
};

const closeDrawerMenu = () => {
	drawerMenu.classList.remove("active");
	AppState.removePageLock();
	// animatePageDown();
	animateLinks.reverse();
	// rotateMenu.reverse();

	removeKeyListener("escape", closeDrawerMenu);
};

menuOpenTrigger.addEventListener("click", openDrawerMenu);
menuCloseTrigger.addEventListener("click", closeDrawerMenu);
