// Initially remove all rows after the first four from the DOM
const rows = Array.from(document.querySelectorAll('.rows')); // Convert NodeList to an array
const parentContainer = rows[0]?.parentNode; // Assume all rows share the same parent
const hiddenRows = []; // Array to store the hidden rows

// Check if rows and parentContainer exist
if (rows.length > 0 && parentContainer) {
    rows.forEach((row, index) => {
        if (index >= 4) {
            hiddenRows.push(row); // Store the row in the array
            parentContainer.removeChild(row); // Remove the row from the DOM
        }
    });
}

let currentIndex = 0; // Start revealing from the first hidden row
const showMoreButton = document.getElementById('showMore');

// Check if the button exists and attach event listener
if (showMoreButton) {
    showMoreButton.addEventListener('click', function() {
        if (currentIndex < hiddenRows.length) {
            const rowToReveal = hiddenRows[currentIndex];

            if (rowToReveal && parentContainer) {
                parentContainer.appendChild(rowToReveal); // Append the row back to the parent container
                currentIndex++;
            }
        }

        // Hide the button when all rows are revealed
        if (currentIndex >= hiddenRows.length) {
            showMoreButton.style.display = 'none';
        }
    });
} else {
    console.error('Show More button not found!');
}
