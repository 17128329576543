import "keen-slider/keen-slider.min.css";
import KeenSlider from "keen-slider";

export default class Slider {
	constructor({ container }) {
		this.container = container;
		this.parentContainer = container.parentNode;
		this.prev = this.parentContainer.querySelector(".slider__prev");
		this.next = this.parentContainer.querySelector(".slider__next");
		this.sliderItems = [...container.querySelectorAll(":scope > *")];
		this.slider = null;
		this.currentIndex = 0;
		this.perView = Number(container.getAttribute("data-per-view")) || 2.5;
		this.spacing = Number(container.getAttribute("data-spacing")) || 48;

		if (!this.sliderItems.length) return;

		this._addClasses();
		this._initSlider();

		if (!this.prev && !this.next) return;
		this._addControls();
	}

	_addClasses() {
		this.container.classList.add("keen-slider");
		this.sliderItems.forEach((item) => item.classList.add("keen-slider__slide"));
	}

	_initSlider() {
		this.slider = new KeenSlider(this.container, {
			centered: true,
			loop: false,
			slides: {
				perView: this.perView,
				spacing: this.spacing,
			},
			breakpoints: {
				'(max-width: 768px)': {
					slides: {
						perView: 1.5,
						spacing: 24,
					},
				},
			},
		});
	}

	_addControls() {
		this._updateControls();

		this.prev.addEventListener("click", () => this._moveToIndex(this.currentIndex - 1));
		this.next.addEventListener("click", () => this._moveToIndex(this.currentIndex + 1));
	}

	_moveToIndex(index) {
		if (index >= 0 && index < this.sliderItems.length) {
			this.slider.moveToIdx(index);
			this.currentIndex = index;
			this._updateControls();
		}
	}

	_updateControls() {
		this.prev.classList.toggle("slider__prev--disabled", this.currentIndex === 0);
		this.next.classList.toggle("slider__next--disabled", this.currentIndex === this.sliderItems.length - 1);
	}
}
