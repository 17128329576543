import { gsap } from "gsap";

gsap.set('.hero__item', {
    autoAlpha: 0,
});

gsap.to('.hero__item', {
    autoAlpha: 1,
    duration: 0.0,
    delay: 0.25,
    stagger: {
        each: 0.1,
        from: "random",
    }
});