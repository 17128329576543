class StateManager {
	constructor() {
		this.pageLock = false;
	}

	setPageLock() {
		this.pageLock = true;
		document.documentElement.classList.add("page-lock");
	}

	removePageLock() {
		this.pageLock = false;
		document.documentElement.classList.remove("page-lock");
	}
}

export const AppState = new StateManager();
